import { useRouter } from "next/router";
import { activeSiteConfig } from "@config/site/site-config";
import { useLiveChat } from "@lib/live-chat/useLiveChat";
import { useMemo, useEffect } from "react";

declare global {
    interface Window {
        embedded_svc: any;
    }
}

export const LiveChatScript = () => {
    const { locale } = useRouter();
    const {
        buttonId,
        deploymentId,
        liveChatId,
        serviceCloudApiUrl,
        serviceCloudChatApiUrl,
        baseLiveAgentContentURL,
        baseLiveAgentURL,
    } = useLiveChat();

    const chatCountry = `ECCO_${activeSiteConfig.country}`;

    const initESW = function () {
        window.embedded_svc.settings.displayHelpButton = true;
        window.embedded_svc.settings.language = locale;

        window.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
        window.embedded_svc.settings.entryFeature = "LiveAgent";

        window.embedded_svc.init(
            serviceCloudApiUrl,
            serviceCloudChatApiUrl,
            null,
            liveChatId,
            chatCountry,
            {
                baseLiveAgentContentURL: baseLiveAgentContentURL,
                deploymentId: deploymentId,
                buttonId: buttonId,
                baseLiveAgentURL: baseLiveAgentURL,
                eswLiveAgentDevName:
                    "EmbeddedServiceLiveAgent_Parent04I1l0000004CU1EAM_17479be136e",
                isOfflineSupportEnabled: false,
            }
        );
    };

    useEffect(() => {
        const loadChatScript = () => {
            // Check if the script is already loaded
            const existingScript = document.querySelector(
                `script[src="${serviceCloudApiUrl}/embeddedservice/5.0/esw.min.js"]`
            );
            if (!existingScript && !window.embedded_svc) {
                const script = document.createElement("script");
                script.setAttribute("src", serviceCloudApiUrl + "/embeddedservice/5.0/esw.min.js");
                script.onload = () => {
                    initESW();
                };
                document.body.appendChild(script);
            } else if (window.embedded_svc) {
                initESW();
            }
        };
        loadChatScript();
        const timeoutId = setTimeout(() => {
            if (window?.embedded_svc && window?.embedded_svc?.settings?.buttonId !== buttonId) {
                //Salesforce chat doesn't allow reinitialization on the page. We need to reload it if locale changed
                window.location.reload();
            }
        }, 150);
        return () => clearTimeout(timeoutId);
    }, [locale, buttonId]);

    const scriptString = useMemo(
        () => `
  	<style type="text/css">
  .embeddedServiceSidebar.layout-docked .dockableContainer {
        right: 0 !important;
        max-height: 100% !important;
        height: 638px !important;
  }
  .embeddedServiceHelpButton .helpButton{
		  display: none !important;
  }

  .embeddedServiceSidebar.layout-docked .dockableContainer .sidebarHeader {
        background-color: white !important;
        color: black !important;
        text-transform: uppercase !important;
        padding-right: 0px !important;
        font-size: 1.25rem !important;
        font-weight: bold !important;
  }

  .embeddedServiceSidebar.layout-docked .dockableContainer .sidebarHeader img {
        display: none;
  }

  .showDockableContainer .sidebarHeader button {
        width: 50px !important;
        height: 50px !important;
        background-color: black !important;
        display: flex;
        justify-content: center;
        align-items: center
  }

  .showDockableContainer .sidebarHeader h2 {
        font-weight: bold
  }

  .showDockableContainer button::before {
    content: none !important;
}

.showDockableContainer button:hover {
    background-color: #464646 !important;
}

  .showDockableContainer svg{
        width: 20px !important;
        height: 20px !important;
  }

  .showDockableContainer {
        width: 520px !important;
        height: 638px !important;
        max-height: 100% !important;
        border-radius: 0px !important;
        right: 0px !important;
  }
  .chatHeaderBranding {
        background-color: white !important;
  }
  .embeddedServiceSidebarButton{
        height: 50px !important;
        border-radius: 0 !important;
        border-color: black !important;
        background-color:black !important;
        font-family: Unica77 !important;
        font-weight: bold !important;
        font-color: white !important;
        color: white !important;
  }

  .embeddedServiceSidebarButton:hover{
        background-color: #464646 !important;
  }

  .waitingCancelChat.uiButton--inverse .label {
  	color: white !important;
  }

  .embeddedServiceSidebarButton.uiButton--inverse .label{
         color: white !important;
  }

  .dialog-button-1.embeddedServiceSidebarButton.uiButton--inverse .label{
         color: black !important;
  }

  .dialog-button-1.embeddedServiceSidebarButton.uiButton--inverse .label:hover{
         color: black !important;
  }

  .embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent{
        background: black;
        border-radius: 0px;
        font-size: 15px !important;
        font-family: Unica77 !important;
  }
  .embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.agent.plaintextContent{
        background: #D2D2D2 !important;
        border-radius: 0px;
        font-size: 15px !important;
        font-family: Unica77 !important;
  }
  .embeddedServiceLiveAgentStateChat .chatSessionStartTime {
        font-size: 15px !important;
        font-weight: bold;
        font-family: Unica77 !important;
  }
  .headerAnnouncement {
        color: black !important;
        background: #D2D2D2 !important;
  }
  .embeddedServiceLiveAgentStateChatInputFooter .chasitorText {
        font-size: 15px !important;
        font-family: Unica77 !important;
  }
  .nameAndTimeContent{
        font-family: Unica77 !important;
  }
  .embeddedServiceSidebar.modalContainer {
         font-family: Unica77 !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI img {
  	display: none !important;
    visibility: hidden !important;
  }

  .embeddedServiceSidebarMinimizedDefaultUI {
  height: 50px !important;
  border-radius: 0px !important;
  background-color: black !important;
  right: 0;
  bottom: 0 !important;
  text-align: center;
  font-wPlhfdcndeqnteight: bold;
}

@media (max-width: 480px) {
  .embeddedServiceSidebarMinimizedDefaultUI {
    right: 15px;
    bottom: 50px !important;
  }
}

@media (min-width: 481px) and (max-width: 992px) {
  .embeddedServiceSidebarMinimizedDefaultUI {
    right: 20px;
    bottom: 50px !important;
  }
}
@media (min-width: 993px) {
  .embeddedServiceSidebarMinimizedDefaultUI {
    right: 30px;
  }
}
  .showDockableContainer .sidebarHeader button.minimizeButton{
		  background-color: #D2D2D2 !important;
		  color: black;
  }
  .showDockableContainer .sidebarHeader button.minimizeButton:hover{
		  background-color: #464646 !important;
  }
  .embeddedServiceInvitation {
  		  display: none
  }

  .dialog-button-1 {
  	background-color: #D2D2D2 !important;
  	color: black !important;
  }

  .dialog-button-1 span {
  	color: black !important;
  }

  .dialog-button-1:hover {
  	background-color: #464646 !important
  }

  .dialog-button-1 span:hover {
  	color: white !important;
  }

  .closeChatButton  {
  	margin-bottom: 10px !important;
	background-color: #D2D2D2 !important;
  }

  .closeChatButton span {
  	color: black !important;
  	text-decoration: none;
  }
  .closeChatButton:hover{
  color: white !important;
  }
  .embeddedServiceSidebarButton:focus {
  	text-decoration: none;
  }

	.closeChatButton span:hover {
  	color: white !important;
  }
  .endChatContainer, .dialogButtonContainer{
  	display: flex;
  	flex-direction: column-reverse;
  }

  #dialogTextTitle{
      text-transform: uppercase;
      color:#464646 !important;
      font-weight: bold;
  }
    .embeddedServiceSidebarDialogState .dialogTextContainer{
      align-items: flex-start;
    }
      #dialogTextBody{
        text-align: start !important;
        padding: 0px !important;
      }
</style>

<!-- Start of Invitations -->
<div class="embeddedServiceInvitation" id="snapins_invite" inert="true" aria-live="assertive" role="dialog" aria-atomic="true">
  <div class="embeddedServiceInvitationHeader" aria-labelledby="snapins_titletext" aria-describedby="snapins_bodytext">
    <img id="embeddedServiceAvatar"/>
    <span class="embeddedServiceTitleText" id="snapins_titletext">Need help?</span>
    <button type="button" id="closeInvite" class="embeddedServiceCloseIcon" aria-label="Exit invitation">&times;</button>
  </div>
  <div class="embeddedServiceInvitationBody">
    <p id="snapins_bodytext">How can we help you?</p>
  </div>
  <div class="embeddedServiceInvitationFooter" aria-describedby="snapins_bodytext">
    <button type="button" class="embeddedServiceActionButton" id="rejectInvite">Close</button>
    <button type="button" class="embeddedServiceActionButton" id="acceptInvite">Start Chat</button>
  </div>
</div>

<script type="text/javascript">
  (function() {
    document.getElementById('closeInvite').onclick = function() { embedded_svc.inviteAPI.inviteButton.rejectInvite(); };
    document.getElementById('rejectInvite').onclick = function() { embedded_svc.inviteAPI.inviteButton.rejectInvite(); }; // use this API call to reject invitations
    document.getElementById('acceptInvite').onclick = function() { embedded_svc.inviteAPI.inviteButton.acceptInvite(); }; // use this API call to start chat from invitations
    document.addEventListener('keyup', function(event) { if (event.keyCode == 27) { embedded_svc.inviteAPI.inviteButton.rejectInvite(); }})
  })();
</script>
<!-- End of Invitations -->
  `,
        [locale]
    );

    return <div id="liveChat" dangerouslySetInnerHTML={{ __html: scriptString }} />;
};

export default LiveChatScript;
